<template>
    <div>
        <b-row>
            <b-col>
                <div class="header pb-6 d-flex align-items-center">
                    <!-- Mask -->
                    <span class="mask bg-white opacity-8"></span>
                    <!-- Header container -->
                    <b-container fluid class="d-flex">
                        <b-row>
                            <!-- <b-col sm="12" md="auto" class="mt-4">
                                <div
                                    @click="$router.back()"
                                    class="icon icon-shape rounded-circle shadow bg-primary text-white"
                                >
                                    <i class="fa fa-arrow-left"></i>
                                </div>
                            </b-col> -->
                            <b-col sm="12" md="auto" class="mt-2">
                                <h1 class="text-primary display-1">
                                    Hackathons
                                </h1>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div>
                    <b-container>
                        <b-row class="mt-4">
                            <b-col
                                sm="12"
                                md="4"
                                v-for="hackathon in hackathons"
                                :key="hackathon.id"
                            >
                                <ListHackathonCard
                                    :id="hackathon.id"
                                    :name="hackathon.name"
                                    :description="hackathon.description"
                                    :phase="hackathon.phaseDisplay"
                                    :is_creator="hackathon.isCreator"
                                    :is_manager="hackathon.isManager"
                                    :starts="hackathon.startDatetime"
                                />
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components
import ListHackathonCard from "@/views/Components/Hackathon/Discovery/ListHackathonCard.vue";

// Queries
import { GET_ALL_HACKATHON_HACKATHON_DISCOVERY } from "@/graphql/queries";

export default {
    name: "HacakthonDiscover",
    components: {
        ListHackathonCard,
    },
    apollo: {
        get_hackathon_hackathon: {
            query: GET_ALL_HACKATHON_HACKATHON_DISCOVERY,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.hackathons = [];
                flattened.allHackathonHackathon.forEach((el) => {
                    this.hackathons.push(el);
                });
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.get_hackathon_hackathon = data;
            },
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_hackathon: null,
            },
            hackathons: [],
        };
    },
    mounted(){

            this.$apollo.queries.get_hackathon_hackathon.refetch()
  
    }, 
    created(){
        graph_utils.set_fetch_cache_and_network(
            this.$apollo.queries.get_hackathon_hackathon
        )
    }
};
</script>

<style></style>
